import client from '../thinMintClient'
import { resolveCampaign, resolveUserFollowingCampaign } from './resolver'
import { SnakeToCamel } from '../types'

const baseEndpoint = '/campaign'

export interface IssuerDocument {
  document: string
  name: string
}

export interface Perk {
  description_long: string
  description_short: string
  id: number
  name: string
  qualifying_investment_amount: number
}

export interface PriorRaise {
  amount_raised: number
  close_date: string
  num_backers: number | null
  round_type: string
  disclaimer?: string
}

export interface StretchGoal {
  amount: number
  title: string
  description?: string
}

export interface CampaignPayload {
  a_x_company: string // required
  accept_after_buffer: boolean
  accept_terms_certificate_language: string | null
  agreements_linking_to_subscription_agreement: string
  capital_goal: number
  capital_limit: number
  capital_pledged: number
  capital_raised: number
  channel_id: string
  close_date: string
  created: string
  creators: string[]
  currently_funding: boolean
  days_remaining_estimate: number
  days_until_close: number
  enabled: boolean
  ends_at: string | null
  escrow_agent: 'north_capital' | 'prime_trust'
  escrow_agent_offering_id: string | null
  express_interest_offset_amount: number
  express_interest_offset_count: number
  facebook_tracking_pixel: string
  fully_disbursed_at: string | null
  hours_remaining_estimate: number | null
  hours_until_close: number | null
  identity_verification_deadline: string
  internal_capital_pledged: number
  internal_users_pledged: number
  investors_total: number | null
  issuer: number | null
  issuer_documents: IssuerDocument[] | []
  modified: string
  name: string
  offering_model: 'equity' | 'loan' | 'reservation'
  payment_update_deadline: string
  perks: Perk[]
  pif_url: string
  pre_money_valuation: number | null
  preview_img: string | null
  previous_round: string | null
  prior_raises: PriorRaise[] | null
  regulation_type: 'A TIER-1' | 'A TIER-2' | 'CF' | 'testing_the_waters' // required
  securities_offered_shortname: string
  securities_offered: string
  share_goal: number
  share_max_buffer: number
  share_minimum_target: number | null
  share_per_person_max: number
  share_per_person_min: number
  share_price: number
  share_reserved_total: number | null
  short_about: string | null
  signaturee_position: string | null
  signaturee: string | null
  slug: string
  stretch_goals?: StretchGoal[] | null
  support_payback_info: boolean
  termination_date: string | null
  termination_reason: string | null
  type: string | null
  users_pledged: number | null
  wire_instructions_pdf: string | null
  within_identity_verification_window: boolean
  within_payment_update_window: boolean
}

export interface Campaign
  extends Omit<
    SnakeToCamel<CampaignPayload>,
    | 'issuerDocuments'
    | 'perks'
    | 'priorRaises'
    | 'stretchGoals'
    | 'preMoneyValuation'
  > {
  amountRaised: number
  backers: number
  capitalRaised: number
  isInBuffer: boolean
  isSellingFast: boolean
  issuerDocuments: SnakeToCamel<IssuerDocument>[]
  maxInvestment: number
  minimumCapitalGoal: number
  minInvestment: number
  offeringString: string
  perks: SnakeToCamel<Perk>[]
  preMoneyValuation: number
  priorRaises: SnakeToCamel<PriorRaise>[]
  priorRaiseTotal: { amount: number; backers: number }
  progress: number
  progressString: string
  progressWithGoalString: string
  regulationTypeLabel: 'Testing the Waters' | 'CF' | 'A'
  stretchGoal: StretchGoal | null
  stretchGoals: SnakeToCamel<StretchGoal>[]
  timeRemaining: { timeRemainingValue: number; timeRemainingDimension: string }
  withinPaymentUpdateWindow: boolean
}

export interface UserFollowingCampaignPayload {
  campaign: string
  following: boolean
}

export interface UserFollowingCampaign {
  campaign: string
  following: boolean
}

export const getAllCampaigns = () =>
  client
    .get(`${baseEndpoint}/`)
    .then((res: { data: CampaignPayload[] }) => res.data.map(resolveCampaign))

export const getCampaign = (slug: string) =>
  client
    .get(`${baseEndpoint}/${slug}/`)
    .then((res: { data: CampaignPayload }) => resolveCampaign(res.data))

export const getUserInvestedCampaigns = () =>
  client
    .get(`${baseEndpoint}/`, { params: { user_invested_in: true } })
    .then((res: { data: CampaignPayload[] }) => res.data.map(resolveCampaign))

export const getIssuerCampaigns = (issuerId: number) =>
  client
    .get(`${baseEndpoint}/?issuer=${issuerId}`)
    .then((res: { data: CampaignPayload[] }) => res.data.map(resolveCampaign))

export const getUserFollowingCampaign = (slug: string) =>
  client
    .get(`/user-following-campaign/?campaign_slug=${slug}`)
    .then((res: { data: UserFollowingCampaignPayload }) =>
      resolveUserFollowingCampaign(res.data)
    )

export const userFollowCampaign = (slug: string) =>
  client
    .post(`/user-following-campaign/`, {
      campaign: slug,
      following: true,
    })
    .then((res: { data: UserFollowingCampaignPayload }) =>
      resolveUserFollowingCampaign(res.data)
    )

export const userUnfollowCampaign = (slug: string) =>
  client
    .post(`/user-following-campaign/`, {
      campaign: slug,
      following: false,
    })
    .then((res: { data: UserFollowingCampaignPayload }) =>
      resolveUserFollowingCampaign(res.data)
    )

export default {
  baseEndpoint,
  getAllCampaigns,
  getCampaign,
  getIssuerCampaigns,
  getUserInvestedCampaigns,
  getUserFollowingCampaign,
  userFollowCampaign,
  userUnfollowCampaign,
}
