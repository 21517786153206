import YouTubePlayer from 'yt-player'
import VimeoPlayer from '@vimeo/player'
import { report, resolveErrorMessage } from '@/utils'
import {
  VideoService,
  VideoPlayer,
  YoutubeVideoPlayer,
  VimeoVideoPlayer,
} from './types'

export const defaultYoutubeAllow =
  'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'

export const defaultYoutubeParams = {
  autoplay: 0,
  mute: 0,
  controls: 1,
  playsinline: 1,
  showinfo: 0,
  rel: 0,
  iv_load_policy: 3,
  modestbranding: 1,
  enablejsapi: 1,
}

export const defaultVimeoParms = {
  mute: 0,
  controls: 1,
  background: 0,
}

export const getIframeSrc = (
  videoId: string,
  params: { [key: string]: number | string } = {},
  service: VideoService
) => {
  if (service === 'youtube') {
    return Object.entries({
      ...defaultYoutubeParams,
      ...params,
    }).reduce(
      (src, [key, val]) => `${src}&${key}=${encodeURIComponent(val)}`,
      `https://www.youtube.com/embed/${videoId}?`
    )
  }

  return Object.entries({
    ...defaultVimeoParms,
    ...params,
  }).reduce(
    (src, [key, val]) => `${src}&${key}=${encodeURIComponent(val)}`,
    `https://player.vimeo.com/video/${videoId}?`
  )
}

export const bindVideoPlayerApi = (
  id: string,
  service: VideoService
): VideoPlayer => {
  const player = {} as VideoPlayer
  let libPlayer: YoutubeVideoPlayer | VimeoVideoPlayer

  if (service === 'youtube') {
    libPlayer = new YouTubePlayer(`#${id}`) as unknown as YoutubeVideoPlayer
  } else {
    libPlayer = new VimeoPlayer(
      document.getElementById(id) as HTMLIFrameElement
    ) as VimeoVideoPlayer
  }

  // normalize methods
  player.load = (videoId: string) => {
    if (service === 'youtube') (libPlayer as YoutubeVideoPlayer).load(videoId)
    if (service === 'vimeo') (libPlayer as VimeoVideoPlayer).loadVideo(videoId)
  }
  player.play = libPlayer.play.bind(libPlayer)
  player.pause = libPlayer.pause.bind(libPlayer)
  player.destroy = libPlayer.destroy.bind(libPlayer)
  player.getVideoId = () => {
    if (service === 'youtube') return (libPlayer as YoutubeVideoPlayer).videoId
    return (libPlayer as VimeoVideoPlayer).getVideoId().then((res) => `${res}`)
  }
  player.getDuration = libPlayer.getDuration.bind(libPlayer)
  player.getCurrentTime = libPlayer.getCurrentTime.bind(libPlayer)
  player.getQuality = () => {
    if (service === 'youtube')
      return (libPlayer as YoutubeVideoPlayer)._player.getPlaybackQuality()
    return (libPlayer as VimeoVideoPlayer).getQuality()
  }
  player.getVideoTitle = () => {
    if (service === 'youtube')
      return (libPlayer as YoutubeVideoPlayer)._player.getVideoData().title
    return (libPlayer as VimeoVideoPlayer).getVideoTitle()
  }

  // normalize events
  player.on = (event: string, cb: () => void) => {
    if (event === 'ready') {
      if (service === 'youtube') libPlayer.on('cued', cb)
      if (service === 'vimeo') libPlayer.on('bufferend', cb)
      return
    }
    if (event === 'playing') {
      if (service === 'youtube') libPlayer.on('playing', cb)
      if (service === 'vimeo') libPlayer.on('play', cb)
      return
    }
    if (event === 'paused') {
      if (service === 'youtube') libPlayer.on('paused', cb)
      if (service === 'vimeo') libPlayer.on('pause', cb)
      return
    }
    if (event === 'unplayable') {
      if (service === 'youtube') libPlayer.on('unplayable', cb)
      return
    }

    libPlayer.on(event, cb)
  }

  return player
}

export const getVideoSegmentProperties = async (
  player: VideoPlayer,
  campaignSlug: string,
  service: VideoService
) => {
  let properties

  // Wrapping this in try/catch because some methods below use
  // undocumented 3rd party API methods.
  try {
    const content_id = await player.getVideoId()
    const total_length = await player.getDuration()
    const position = await player.getCurrentTime()
    const video_quality = await player.getQuality()
    const title = await player.getVideoTitle()

    properties = {
      title,
      content_id,
      project: campaignSlug,
      total_length,
      position,
      video_quality,
      service,
    }
  } catch (err) {
    report.error(resolveErrorMessage(err as Error))
    properties = {
      title: 'Unknown',
      content_id: 'Unknown',
      project: campaignSlug,
      total_length: 0,
      position: 0,
      video_quality: 'Unknown',
      service,
    }
  }

  return properties
}
