import React from 'react'
import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'
import { Switch, Text } from '@/components/common'

export interface Props {
  name: string
  text: string | JSX.Element
  className?: string
}

const ToggleInput: React.FC<Props> = ({ text, name, className }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  return (
    <Switch.Group>
      <div
        className={classNames(
          {
            'flex flex-row items-center w-full space-x-2.5': true,
          },
          className
        )}
      >
        <Text as="div" preset="body.md" className="flex-1">
          {text}
        </Text>

        <Switch
          onText="YES"
          offText="NO"
          checked={!!field.value}
          onChange={() => setFieldValue(name, !field.value)}
          error={meta.touched && !!meta.error}
          testId={name}
        />

        {/* Hidden input so this toggle can be scrolled to if error */}
        <input type="hidden" name={name} />
      </div>
    </Switch.Group>
  )
}

export default ToggleInput
