import { useMemo } from 'react'
import useApi from '@/services/hooks/useApiV2'
import {
  getUserInvestedCampaigns,
  Campaign,
} from '@/services/api/campaignV2/campaign'
import useAuth from '@/services/hooks/useAuth'
import useSiteAssets from '../useSiteAssets/useSiteAssets'
import { whiteLabelUtil } from '@/utils'

const useUserInvestedCampaigns = () => {
  const { user } = useAuth()
  const { domain } = useSiteAssets()

  const { data, isLoading, isError } = useApi<Campaign[]>({
    key: user ? `campaigns/${user.id}/` : false,
    request: () => getUserInvestedCampaigns(),
  })

  const userInvestedCampaigns = useMemo(
    () => data?.filter((c) => whiteLabelUtil.isAllowed(c, domain)),
    [data, domain]
  )

  return {
    userInvestedCampaigns,
    isLoading,
    isError,
  }
}

export default useUserInvestedCampaigns
