import classnames from 'classnames'
import NextImage from 'next/image'
import Link from 'next/link'
import React, { useEffect, useMemo, useState, ReactElement } from 'react'

import Button from '../Button'
import ButtonBase from '../ButtonBase'
import ImageLoader from '../ImageLoader'
import Modal from '../CardModal'
import Text from '../Text/Text'
import Image from '@/components/contentful/Image'
import { Users } from '@/components/svg'
import { Campaign as ICampaign } from '@/services/api/campaignV2/campaign'
import { getCampaignAssets } from '@/services/api/campaignAssets'
import useApi from '@/services/hooks/useApiV2'
import { formatMoney, progressUtil } from '@/utils'
import {
  ICampaignAssets,
  ICampaignAssetsFields,
} from 'src/types/generated/contentful'
import { FaEllipsisH } from 'react-icons/fa'
import SellingFast from '@/components/pages/CampaignPages/InvestComponents/SellingFast'
import { FormatMoneyOptions } from 'src/utils/formatMoney'

interface Props {
  campaign: ICampaign
  className?: string
  layout?: 'vertical' | 'horizontal'
  noDescription?: boolean
}

interface ChildLinkProps {
  children: ReactElement<any, any> | null
  className?: string
  href: string | null
}

const MONEY_FORMAT: FormatMoneyOptions = {
  zeroFractionDigits: true,
}

const ChildLink: React.FC<ChildLinkProps> = ({ href, children, className }) => {
  if (!href) return children

  return (
    <Link href={href}>
      <a className={className}>{children}</a>
    </Link>
  )
}

export const CampaignCard: React.FC<Props> = ({
  campaign,
  className,
  layout = 'vertical',
  noDescription,
}) => {
  const [assets, setAssets] = useState<ICampaignAssetsFields | undefined>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isTestingTheWaters = campaign.regulationType === 'testing_the_waters'
  const clarificationSymbol = campaign.isSellingFast === true ? '†' : ''
  const url = `/${campaign.slug}`
  // 16:9 aspect ratio
  const imgHeight = 341
  const imgWidth = 607 // max width it will need

  const ctaLanguage = useMemo(() => {
    if (isTestingTheWaters) {
      return 'Express Interest'
    }
    if (!campaign.currentlyFunding) {
      return 'Offering Is Closed'
    }

    return 'Back This Project'
  }, [isTestingTheWaters, campaign.currentlyFunding])

  const { data, isLoading } = useApi<{ campaignAssets: ICampaignAssets }>(
    getCampaignAssets({ slug: campaign.slug })
  )

  useEffect(() => {
    if (!data?.campaignAssets?.fields) return

    setAssets(data.campaignAssets.fields)
  }, [data])

  return (
    <div
      className={classnames(
        'bg-white flex flex-col h-full shadow shadow-gray-8 rounded-2xl w-full overflow-hidden',
        {
          'sm:flex-row': layout === 'horizontal',
        },
        className
      )}
    >
      <div
        className={classnames({
          'sm:p-6 sm:pr-3 sm:flex-[0_0_50%]': layout === 'horizontal',
        })}
      >
        {isLoading && <ImageLoader />}
        {!isLoading && (
          <ChildLink href={url}>
            <>
              {/* We have an image from Contentful */}
              {assets?.image && (
                <Image
                  className={classnames('w-full', {
                    'sm:rounded-lg': layout === 'horizontal',
                  })}
                  image={assets.image}
                  fit="fill"
                  fill="face"
                  lazy
                  height={imgHeight}
                  width={imgWidth}
                />
              )}
              {/* Fallback to previewImg from campaign */}
              {!assets?.image && campaign.previewImg && (
                <NextImage
                  className={classnames('w-full', {
                    'sm:rounded-lg': layout === 'horizontal',
                  })}
                  src={campaign.previewImg}
                  alt={`${campaign.name} hero`}
                  loading="lazy"
                  height={imgHeight}
                  width={imgWidth}
                />
              )}
            </>
          </ChildLink>
        )}
      </div>

      <div
        className={classnames('flex flex-col flex-1 pt-4 pb-5 px-6 text-left', {
          'sm:pt-2 sm:pb-4 sm:px-4': layout === 'vertical',
          'sm:p-6 sm:pl-3': layout === 'horizontal',
        })}
      >
        <ChildLink href={url} className="mb-2 self-start">
          <>
            <Text as="h4" preset="heading.lg">
              {campaign.name}
            </Text>
            {assets?.subtitle && (
              <Text as="p" preset="body.lg">
                {assets.subtitle}
              </Text>
            )}
          </>
        </ChildLink>
        <Text as="p" preset="body.lg" className="font-light mb-4">
          {`${campaign.type} | ${campaign.offeringString}`}
        </Text>

        {campaign.shortAbout && !noDescription && (
          <Text as="p" preset="body.md" className="mb-4">
            {campaign.shortAbout}
          </Text>
        )}

        <div className="mb-4">
          {campaign.capitalRaised >= campaign.minimumCapitalGoal &&
            campaign.stretchGoal &&
            campaign.currentlyFunding && (
              <Text as="p" preset="heading.xs" className="font-medium mb-2">
                {campaign.stretchGoal.title}
              </Text>
            )}
          {campaign.currentlyFunding && !isTestingTheWaters && (
            <div className="w-full bg-gray-300 rounded-lg h-2 mb-2">
              <div
                className="h-full bg-oxide-800 rounded-lg"
                style={{
                  // needs min, or it looks bad with rounded corners
                  minWidth: 8,
                  width: progressUtil.getProgressWidth(campaign.progress),
                }}
              />
            </div>
          )}
          <Text as="p" preset="body.lg">
            <Text as="span" preset="heading.lg" className="font-medium">
              {formatMoney(campaign.amountRaised, MONEY_FORMAT)}
            </Text>{' '}
            {campaign.progressWithGoalString}
          </Text>
        </div>

        <hr className="border-gray-300 mb-2" />

        <div className="flex justify-between mb-6">
          <Text as="span" preset="heading.sm" className="font-normal">
            <Users className="inline-block mr-1 -mt-1" />
            {Number(campaign.backers).toLocaleString()} People
          </Text>
          {campaign.currentlyFunding && !isTestingTheWaters && (
            <Text as="span" preset="heading.sm" className="font-normal">
              {Number(
                campaign.timeRemaining.timeRemainingValue
              ).toLocaleString()}
              <span className="font-light lowercase ml-1">
                {`${campaign.timeRemaining.timeRemainingDimension} left`}{' '}
                {clarificationSymbol}
              </span>
            </Text>
          )}
        </div>

        {campaign.priorRaises.length > 0 && (
          <ButtonBase
            className="bg-gray-8 bg-opacity-50 hover:bg-opacity-80 flex flex-col mb-6 p-2 text-left relative rounded transition-all duration-150"
            onClick={(e) => {
              e.preventDefault()
              setIsModalOpen(true)
            }}
            data-cy="campaign-card-previous-rounds-details"
          >
            <Text as="h5" preset="heading.xs" className="font-normal">
              Previous Rounds:
            </Text>
            <div className="flex flex-row">
              {campaign.priorRaiseTotal?.backers && (
                <div className="mr-5">
                  <Text as="p" preset="heading.md">
                    {campaign.priorRaiseTotal.backers.toLocaleString()}
                  </Text>
                  <Text as="p" preset="body.sm">
                    Backers
                  </Text>
                </div>
              )}
              <div>
                <Text as="p" preset="heading.md">
                  {formatMoney(campaign.priorRaiseTotal?.amount, MONEY_FORMAT)}
                </Text>
                <Text as="p" preset="body.sm">
                  Raised
                </Text>
              </div>
            </div>
            <FaEllipsisH className="absolute right-2.5 text-lg top-1.5" />
          </ButtonBase>
        )}

        <SellingFast campaign={campaign} className="my-2 text-black" />

        <ChildLink href={url} className="mt-auto">
          <Button
            variant="primary"
            className="w-full bg-oxide-800 mt-auto rounded-lg"
            tabIndex={-1}
          >
            {ctaLanguage}
          </Button>
        </ChildLink>
      </div>
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <div className="p-6">
          <Text as="h2" preset="heading.lg" className="mb-4">
            Previous Rounds:
          </Text>

          {campaign.priorRaises.map((round) => (
            <Text
              as="p"
              preset="body.lg"
              key={campaign.name + round.closeDate + round.amountRaised}
              className="flex flex-row mb-2 last:mb-0"
            >
              <Text as="span" preset="body.lg" className="font-semibold mr-2">
                {round.closeDate}
                {round.roundType ? ` ${round.roundType}:` : ':'}
              </Text>
              {round.numBackers &&
                `${round.numBackers.toLocaleString()} Backers, `}
              {formatMoney(round.amountRaised, MONEY_FORMAT) + ' Raised'}
            </Text>
          ))}
        </div>
      </Modal>
    </div>
  )
}

export default CampaignCard
