import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import Modal, { Props as ModalProps } from '../Modal/Modal'
import Button from '../Button'
import { Close } from '@/components/svg'
import useWindowSize from '@/services/hooks/useWindowSize'

export interface Props extends ModalProps {
  fillHeightOnMobile?: boolean
}

export const CardModal = ({
  setIsOpen,
  fillHeightOnMobile = false,
  children,
  ...rest
}: Props) => {
  const { innerWidth, innerHeight } = useWindowSize()
  const isMobile = useMemo(() => innerWidth <= 640, [innerWidth])
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <Modal
      {...rest}
      setIsOpen={setIsOpen}
      overlayColor="rgba(0,0,0,0.25)"
      transition={isMobile ? 'slideUp' : 'scaleIn'}
    >
      <Button
        variant="primary"
        color="lightGray"
        size="sm"
        className="absolute top-4 sm:top-6 right-6 z-10"
        onClick={handleClose}
        aria-label="Close Dialog"
      >
        <Close className="w-6 h-6 -mx-2" />
      </Button>
      <div
        className={classNames({
          'relative bg-white sm:rounded sm:shadow-card overflow-y-auto': true,
          'w-screen rounded-tl-[1.5rem] rounded-tr-[1.5rem]': isMobile,
        })}
        style={{
          height: isMobile && fillHeightOnMobile ? innerHeight - 20 : undefined,
          maxHeight:
            isMobile && fillHeightOnMobile ? undefined : innerHeight - 20,
        }}
      >
        {children}
      </div>
    </Modal>
  )
}

CardModal.Title = Modal.Title

export default CardModal
