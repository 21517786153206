import {
  Campaign,
  CampaignPayload,
  UserFollowingCampaignPayload,
} from './campaign'
import { formatMoney, casingUtil } from '@/utils'
import { FormatMoneyOptions } from 'src/utils/formatMoney'

const formatMoneyOpts: FormatMoneyOptions = {
  zeroFractionDigits: true,
}

export const getAmountRaised = (c: Campaign) => {
  const capitalGoal = Number(c.capitalGoal)
  const capitalRaised = Number(c.capitalRaised)

  if (c.regulationType === 'testing_the_waters') {
    return Number(c.capitalPledged)
  }

  return capitalRaised > capitalGoal ? capitalGoal : capitalRaised
}

// TODO: getCapitalRaised appears to be a duplicate of getAmountRaised
export const getCapitalRaised = getAmountRaised

export const getTimeRemaining = (
  c: Campaign,
  threshold = 72
): {
  timeRemainingValue: number
  timeRemainingDimension: 'Hour' | 'Hours' | 'Day' | 'Days'
} => {
  const capitalGoal = Number(c.capitalGoal)
  const capitalRaised = Number(c.capitalRaised)
  const daysRemainingEstimate = Number(c.daysRemainingEstimate)
  const daysUntilClose = Number(c.daysUntilClose)
  const hoursRemainingEstimate = Number(c.hoursRemainingEstimate)
  const hoursUntilClose = Number(c.hoursUntilClose)

  if (hoursRemainingEstimate < threshold && capitalRaised < capitalGoal) {
    const hourOrHours = hoursRemainingEstimate === 1 ? 'Hour' : 'Hours'
    return {
      timeRemainingValue: hoursRemainingEstimate,
      timeRemainingDimension: hourOrHours,
    }
  }

  if (hoursUntilClose < threshold) {
    const hourOrHours = hoursUntilClose === 1 ? 'Hour' : 'Hours'
    return {
      timeRemainingValue: hoursUntilClose,
      timeRemainingDimension: hourOrHours,
    }
  }

  if (daysRemainingEstimate < daysUntilClose && capitalRaised < capitalGoal) {
    const dayOrDays = daysRemainingEstimate === 1 ? 'Day' : 'Days'

    return {
      timeRemainingValue: daysRemainingEstimate,
      timeRemainingDimension: dayOrDays,
    }
  }
  const dayOrDays = daysUntilClose === 1 ? 'Day' : 'Days'
  return {
    timeRemainingValue: daysUntilClose,
    timeRemainingDimension: dayOrDays,
  }
}

export const getBackers = (c: Campaign) => {
  const backers =
    c.regulationType === 'testing_the_waters'
      ? c.usersPledged
      : c.investorsTotal

  return backers || 0
}

export const getMinimumCapitalGoal = (c: Campaign) =>
  Number(c.shareMinimumTarget) * c.sharePrice

export const getStretchGoals = ({ stretchGoals }: Campaign) => {
  if (!stretchGoals) return []

  return stretchGoals.sort((a, b) => {
    return a.amount > b.amount ? 1 : -1
  })
}

export const getStretchGoal = (c: Campaign) => {
  const capitalRaised = Number(c.capitalRaised)
  const stretchGoals = getStretchGoals(c)
  const minimumCapitalGoal = getMinimumCapitalGoal(c)

  if (stretchGoals.length === 0 || capitalRaised < minimumCapitalGoal)
    return null

  const remainingGoals = stretchGoals.filter(
    (goal) => goal.amount > capitalRaised
  )

  if (remainingGoals.length === 0) {
    return null
  }

  const { amount, title } = remainingGoals[0]

  return { amount: amount, title }
}

export const getProgress = (c: Campaign) => {
  const capitalGoal = Number(c.capitalGoal)
  const capitalRaised = Number(c.capitalRaised)
  const minimumCapitalGoal = getMinimumCapitalGoal(c)

  if (capitalRaised < minimumCapitalGoal) {
    return (capitalRaised / minimumCapitalGoal) * 100
  }

  const stretchGoal = getStretchGoal(c)
  if (stretchGoal && capitalRaised < stretchGoal.amount) {
    return (capitalRaised / stretchGoal.amount) * 100
  } else if (capitalRaised < capitalGoal) {
    return (capitalRaised / capitalGoal) * 100
  }

  return 100
}

export const getProgressString = (c: Campaign) => {
  if (c.regulationType === 'testing_the_waters') {
    return 'Expressed Interest'
  }

  const capitalGoal = Number(c.capitalGoal)
  const capitalRaised = Number(c.capitalRaised)
  const minimumCapitalGoal = getMinimumCapitalGoal(c)

  if (capitalRaised > capitalGoal) {
    return `of ${formatMoney(Math.floor(capitalGoal), formatMoneyOpts)} max`
  }

  return capitalRaised > minimumCapitalGoal
    ? `of ${formatMoney(
        Math.floor(c.shareGoal * c.sharePrice),
        formatMoneyOpts
      )} max`
    : `of ${formatMoney(Math.floor(minimumCapitalGoal), formatMoneyOpts)} min`
}

// This may replace getProgressString - it more closely matches getProgress which includes stretch goals
export const getProgressWithGoalString = (c: Campaign) => {
  if (!c.currentlyFunding) {
    return 'Raised'
  }

  if (c.regulationType === 'testing_the_waters') {
    return 'Expressed Interest'
  }

  const stretchGoal = getStretchGoal(c)

  if (stretchGoal) {
    return `of ${formatMoney(
      Math.floor(stretchGoal.amount),
      formatMoneyOpts
    )} goal`
  }

  return getProgressString(c)
}

export const getMinInvestment = ({ sharePerPersonMin, sharePrice }: Campaign) =>
  Math.floor(sharePerPersonMin * sharePrice)

export const getMaxInvestment = ({ sharePerPersonMax, sharePrice }: Campaign) =>
  Math.floor(sharePerPersonMax * sharePrice)

export const getIsSellingFast = (c: Campaign) => {
  const capitalGoal = Number(c.capitalGoal)
  const capitalRaised = Number(c.capitalRaised)
  const daysRemainingEstimate = Number(c.daysRemainingEstimate)
  const daysUntilClose = Number(c.daysUntilClose)
  const hoursRemainingEstimate = Number(c.hoursRemainingEstimate)

  if (c.regulationType === 'testing_the_waters') {
    return false
  }

  if (hoursRemainingEstimate < 36 && capitalRaised < capitalGoal) {
    return true
  }

  if (daysRemainingEstimate < daysUntilClose && capitalRaised < capitalGoal) {
    return true
  }

  return false
}

export const getIsInBuffer = (c: Campaign) => {
  const capitalGoal = Number(c.capitalGoal)
  const capitalLimit = Number(c.capitalLimit)
  const capitalRaised = Number(c.capitalRaised)

  if (c.regulationType === 'testing_the_waters') {
    return false
  }

  return !!(
    capitalRaised >= capitalGoal &&
    capitalRaised < capitalLimit &&
    c.currentlyFunding
  )
}

export const getPriorRaiseTotal = (c: Campaign) => {
  const initial = { amount: 0, backers: 0 }

  if (c.priorRaises === null || c.priorRaises.length === 0) return initial

  return c.priorRaises.reduce(
    (acc, cur) => ({
      amount: acc.amount + (cur.amountRaised || 0),
      backers: acc.backers + (cur.numBackers || 0),
    }),
    initial
  )
}

const getRegulationTypeLabel = (c: Campaign) => {
  let regulationTypeLabel: 'CF' | 'Testing the Waters' | 'A' = 'A'
  if (c.regulationType === 'testing_the_waters') {
    regulationTypeLabel = 'Testing the Waters'
  } else if (c.regulationType === 'CF') {
    regulationTypeLabel = 'CF'
  }

  return regulationTypeLabel
}

export const getOfferingString = (c: Campaign) => {
  if (c.regulationType === 'testing_the_waters') return 'Offering: Reservation'

  const regulationTypeLabel = getRegulationTypeLabel(c)

  return regulationTypeLabel === 'A' ? 'Offering: Reg A+' : 'Offering: Reg CF'
}

export const resolveCampaign = (campaign: CampaignPayload): Campaign => {
  const c = {
    ...casingUtil.snakeToCamel(campaign),
  } as Campaign

  return {
    ...c,
    amountRaised: getAmountRaised(c),
    backers: getBackers(c),
    capitalRaised: getCapitalRaised(c),
    isInBuffer: getIsInBuffer(c),
    isSellingFast: getIsSellingFast(c),
    maxInvestment: getMaxInvestment(c),
    minimumCapitalGoal: getMinimumCapitalGoal(c),
    minInvestment: getMinInvestment(c),
    offeringString: getOfferingString(c),
    preMoneyValuation: c.preMoneyValuation || 0,
    priorRaiseTotal: getPriorRaiseTotal(c),
    progress: getProgress(c),
    progressString: getProgressString(c),
    progressWithGoalString: getProgressWithGoalString(c),
    regulationTypeLabel: getRegulationTypeLabel(c),
    stretchGoals: getStretchGoals(c),
    stretchGoal: getStretchGoal(c),
    timeRemaining: getTimeRemaining(c),
  }
}

export const resolveUserFollowingCampaign = (
  followingCampaign: UserFollowingCampaignPayload
) => {
  return {
    ...followingCampaign,
  }
}
