import client from '../thinMintClient'
import { InvestmentForm, InvestmentPayload, InvestmentPerk } from './types'
import { resolveInvestment, resolveInvestmentPayload } from './resolver'

const baseEndpoint = '/investments'

export const getAllInvestments = () => {
  return {
    key: `${baseEndpoint}/all`,
    request: () => client.get(`${baseEndpoint}/`),
    resolve: (res: { data: { results: InvestmentPayload[] } }) =>
      res.data.results.map(resolveInvestment),
  }
}

export const createInvestment = () => {
  return {
    request: (payload: InvestmentForm) =>
      client.post(`${baseEndpoint}/`, resolveInvestmentPayload(payload)),
    resolve: (res: { data: InvestmentPayload }) => resolveInvestment(res.data),
  }
}

export const updateInvestment = (investmentId: string) => {
  return {
    request: (payload: InvestmentForm) =>
      client.put(
        `${baseEndpoint}/${investmentId}/`,
        resolveInvestmentPayload(payload)
      ),
    resolve: (res: { data: InvestmentPayload }) => resolveInvestment(res.data),
  }
}

export const getUserInvestmentPerks = () => {
  return {
    key: `${baseEndpoint}/user-perks`,
    request: () => client.get(`/user-perks/`),
    resolve: (res: { data: { results: InvestmentPerk[] } }) => {
      return res.data.results
    },
  }
}

export const cancelInvestment = (investmentId: string) => {
  return {
    key: `${baseEndpoint}/all`,
    request: () =>
      client.post(`${baseEndpoint}/${investmentId}/cancel_investment/`, {}),
    resolve: (res: { data: { id: string } }) => res.data,
  }
}

export const reconfirmInvestment = (investmentId: string) => {
  return {
    key: `${baseEndpoint}/all`,
    request: () =>
      client.post(`${baseEndpoint}/${investmentId}/reconfirm/`, {}),
    resolve: (res: { data: InvestmentPayload }) => resolveInvestment(res.data),
  }
}

export default {
  baseEndpoint,
  getAllInvestments,
  createInvestment,
  updateInvestment,
  getUserInvestmentPerks,
  cancelInvestment,
  reconfirmInvestment,
}
