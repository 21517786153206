import React from 'react'
import classNames from 'classnames'
import FormSelect from '../FormSelect/FormSelect'
import FormRadio from '../FormRadio'
import FormNumberInput from '../FormNumberInput'
import FormFileInput from '../FormFileInput'
import FormPhoneInput from '../FormPhoneInput'
import FormDropzone from '../FormDropzone/FormDropzone'
import FormToggleInput from '../FormToggleInput'
import FormSecretInput from '../FormSecretInput'
import FormInputDefault from './FormInputDefault'
import FormCreditCardInput from '../FormCreditCardInput'
import { RenderedInputProps, RenderedFileInputProps } from './types'

export const getInputClassName = (
  hasError: boolean,
  disabled = false,
  type?: string,
  prefix = false
) =>
  type === 'radio'
    ? ''
    : classNames({
        'w-full border-gray-6 focus:outline-none bg-transparent appearance-none h-12 border rounded-[4px] px-2 hover:ring-1 focus:ring-1':
          true,
        'focus:ring-oxide-3 focus:border-oxide hover:ring-gray-6': !hasError,
        'border-red ring-red focus:ring-red': hasError,
        'opacity-50 pointer-events-none': disabled,
        'pl-6': type !== 'number' && prefix,
      })

export const renderFileInput = ({
  field,
  name,
  type,
  maxFiles = 0,
  uploadFile,
  removeFile,
  text,
  hasError,
  disabled,
  ...rest
}: RenderedFileInputProps) => {
  if (maxFiles > 1 && uploadFile && removeFile) {
    return (
      <FormDropzone
        {...rest}
        name={name}
        description={text}
        maxFiles={maxFiles}
        uploadFile={uploadFile}
        removeFile={removeFile}
      />
    )
  }

  return (
    <FormFileInput
      {...rest}
      name={name}
      value={field.value}
      className={getInputClassName(hasError, disabled, type)}
    />
  )
}

export const renderInput = ({
  field,
  name,
  isSecretMasked,
  type,
  options,
  text,
  prefix,
  hasError,
  disabled,
  countries,
  ...rest
}: RenderedInputProps) => {
  const props = {
    ...field,
    ...rest,
    name,
    id: name,
    disabled,
    className: getInputClassName(hasError, disabled, type, !!prefix),
  }

  if (isSecretMasked) {
    return <FormSecretInput {...props} type={type} />
  }

  if (type === 'select' && options) {
    return <FormSelect {...props} options={options} />
  }

  if (type === 'radio' && options) {
    return <FormRadio {...props} options={options} />
  }

  if (type === 'number') {
    return <FormNumberInput {...props} prefix={prefix} />
  }

  if (type === 'tel') {
    return <FormPhoneInput {...props} countries={countries} />
  }

  if (type === 'creditCard') {
    return <FormCreditCardInput {...props} />
  }

  if (type === 'toggle' && text) {
    return <FormToggleInput name={name} text={text} />
  }

  return <FormInputDefault {...props} type={type} />
}
