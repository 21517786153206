import React, { useState, ElementType } from 'react'
import { Button, ButtonBase, Text, CardModal } from '@/components/common'
import { extraConfig } from '@/services/api/siteAssets/resolver'

interface Props {
  domain: keyof typeof extraConfig
  children: React.ReactNode
  path?: string
  as?: ElementType
  className?: string
}

export const InterstitialLink: React.FC<Props> = ({
  domain,
  children,
  path = '',
  as,
  className,
  ...rest
}) => {
  const siteAssets = extraConfig[domain]
  const [modalIsOpen, setModalOpen] = useState(false)

  if (!siteAssets) return null

  const Btn = as || ButtonBase

  const prompt =
    domain === 'invest.angel.com'
      ? `You are now leaving this website to enter ${siteAssets.title}, a FINRA regulated portal.`
      : `You are now leaving this website to go to ${siteAssets.title}`

  const url = `https://${domain}/${path}`

  return (
    <>
      <Btn onClick={() => setModalOpen(true)} className={className} {...rest}>
        {children}
      </Btn>

      <CardModal isOpen={modalIsOpen} setIsOpen={setModalOpen}>
        <div className="w-full max-w-xl pt-16 px-6 pb-6">
          <Text as="p" preset="body.lg">
            {prompt}
          </Text>

          <div className="flex justify-end mt-4">
            <Button
              color="gray"
              size="sm"
              className="mr-3"
              onClick={() => setModalOpen(false)}
            >
              Stay
            </Button>

            <Button size="sm" onClick={() => window.open(url, '_blank')}>
              Continue to {siteAssets.title}
            </Button>
          </div>
        </div>
      </CardModal>
    </>
  )
}

export default InterstitialLink
