import { creditCardUtil } from '@/utils'
import { PaymentRequest, PaymentForm } from './types'

export const resolvePaymentForm = (
  investment: string,
  {
    paymentType: payment_type,
    payment: {
      fullName: cc_name = '',
      cardNumber: cc_number = '',
      monthYear = '',
      cvc: cc_cvc = '',
      accountNumber: ach_account_number = '',
      routingNumber: ach_routing_number = '',
      accountType: ach_account_type = 'Checking',
      plaidAccountId: plaid_account_id = '',
      plaidToken: plaid_token = '',
    },
  }: PaymentForm
): PaymentRequest => {
  if (payment_type === 'credit_card') {
    return {
      payment_type,
      investment,
      cc_name,
      cc_card_type: creditCardUtil.detectTypeInitials(cc_number),
      cc_number,
      cc_cvc,
      cc_expiration_month: monthYear.split('/')[0],
      cc_expiration_year: monthYear.split('/')[1],
    }
  }

  if (payment_type === 'ach') {
    return {
      payment_type,
      investment,
      ach_account_number,
      ach_routing_number,
      ach_account_type,
    }
  }

  if (payment_type === 'plaid') {
    return {
      payment_type,
      investment,
      plaid_account_id,
      plaid_token,
    }
  }

  return {
    payment_type: 'wire',
    investment,
  }
}
