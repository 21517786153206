import { casingUtil } from '@/utils'
import { Investment, InvestmentForm, InvestmentPayload } from './types'
import {
  resolveBirthday,
  resolveBirthdayPayload,
  resolvePhoneNumber,
} from '../resolvers/resolvers'

export const resolveInvestment = ({
  account,
  has_ssn,
  birthday,
  phone_number,
  ...rest
}: InvestmentPayload) => {
  return {
    ...casingUtil.snakeToCamel(rest),
    accountId: account,
    hasSsn: has_ssn,
    birthday: resolveBirthday(birthday),
    phoneNumber: resolvePhoneNumber(phone_number),
  } as Investment
}

export const resolveInvestmentPayload = ({
  accountId,
  amount,
  birthday,
  ...rest
}: InvestmentForm) => {
  return {
    ...casingUtil.camelToSnake(rest),
    account: accountId,
    amount: typeof amount === 'string' ? parseFloat(amount) : amount,
    birthday: resolveBirthdayPayload(birthday),
  }
}
